import React from 'react';
import PropTypes from 'prop-types';
import Styles from './ProjectCard.module.scss';

const ProjectCard = ({ name, desc, links }) => {
    return (
        <div className={Styles.card}>
            <div className={Styles.header}>
                <h2>{name}</h2>
                {links ? (
                    links.map(linkData => {
                        return (
                            <a key={linkData.link} href={linkData.link}>
                                {linkData.text}
                            </a>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
            <div className={Styles.body}>{desc}</div>
        </div>
    );
};

ProjectCard.propTypes = {
    name: PropTypes.string.isRequired,
    desc: PropTypes.element.isRequired,
    links: PropTypes.array,
};

export default ProjectCard;
