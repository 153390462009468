import React from 'react';
import Styles from './Links.module.scss';

const Links = () => {
    return (
        <>
            <div className={Styles.page}>
                <div className={`${Styles.header} ${Styles.minor}`}>
                    <h1>{'Links'}</h1>
                </div>
                <div className={`${Styles.body} ${Styles.extended}`}>
                    <div className={Styles.linksList}>
                        <a
                            href={
                                'https://www.linkedin.com/in/mayank-mathur-205767197/'
                            }
                        >
                            LinkedIn
                        </a>
                        <a href={'https://github.com/Mynk-9/'}>Github</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Links;
