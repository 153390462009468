import React, { useState, useEffect, useRef } from 'react';
import Styles from './MainPageAnimation.module.scss';
import TRUNK from 'vanta/dist/vanta.trunk.min';
import * as THREE from 'three';
import p5 from 'p5';

const MainPageAnimation = () => {
    const [vantaEffect, setVantaEffect] = useState(0);
    const animComponent = useRef(null);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                TRUNK({
                    el: animComponent.current,
                    THREE: THREE,
                    p5: p5,
                    spacing: 1.5,
                }),
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);
    return <div ref={animComponent} className={Styles.animComponent} />;
};

export default MainPageAnimation;
