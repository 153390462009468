import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './MainPage.module.scss';

import Typing from '../../components/typing/Typing';

const WelcomePage = () => {
    const isDesktop = window.innerWidth >= 550;

    return (
        <>
            <div className={Styles.page}>
                <div className={Styles.header}>
                    <div className={Styles.banner}>
                        <h1>
                            <Typing text={'Mayank Mathur'} />
                        </h1>
                        <p className={Styles.description}>
                            {'Full-Stack Developer. Amateur Philatelist. Poet.'}
                        </p>
                        <p className={Styles.links}>
                            <a href="https://github.com/Mynk-9/">Github</a>
                            <Link to={'/blogs'}>Blog</Link>
                        </p>
                    </div>
                </div>
                <div className={`${Styles.body} ${Styles.expand}`}>
                    <div className={Styles.heading}>
                        <h2>Projects</h2>
                        <span>
                            <Link to={'/projects'}>details</Link>
                        </span>
                    </div>
                    <div className={Styles.projects}>
                        <details open={isDesktop}>
                            <summary>BeautPlayer</summary>
                            <p>
                                {
                                    'Streaming music player geared towards local network. Built on MERN Stack.'
                                }
                                <br />
                                <a href="https://github.com/Mynk-9/beautplayer">
                                    Github
                                </a>
                            </p>
                        </details>
                        <details open={isDesktop}>
                            <summary>DOMParser</summary>
                            <p>
                                {
                                    'Small XML DOM parsing library built using C++14.'
                                }
                                <br />
                                <a href="https://github.com/Mynk-9/DOM-Parser">
                                    Github
                                </a>
                            </p>
                        </details>
                        <details open={isDesktop}>
                            <summary>wallpepper</summary>
                            <p>
                                {
                                    'Windows UWP application for fetching latest wallpapers from bing and windows spotlight, save and create wallpaper.'
                                }
                                <br />
                                <a href="https://github.com/Mynk-9/DOM-Parser">
                                    Github
                                </a>
                            </p>
                        </details>
                        <details open={isDesktop}>
                            <summary>Cowin Poller</summary>
                            <p>
                                {
                                    'Small Node.js script for polling govt APIs to check for Covid-19 vaccine availability.'
                                }
                                <br />
                                <a href="https://github.com/Mynk-9/cowin-poller">
                                    Github
                                </a>
                            </p>
                        </details>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WelcomePage;
