import React from 'react';
import ProjectCard from '../../components/projectcard/ProjectCard';
import Styles from './Projects.module.scss';

const Projects = () => {
    return (
        <div className={Styles.page}>
            <div className={`${Styles.header} ${Styles.minor}`}>
                <h1>{'Projects'}</h1>
            </div>
            <div className={Styles.body}>
                <ProjectCard
                    name={'BeautPlayer'}
                    links={[
                        {
                            link: 'https://github.com/Mynk-9/beautplayer',
                            text: 'Github',
                        },
                    ]}
                    desc={
                        <>
                            <em>
                                {
                                    'A beautifully designed streaming music player built on MERN stack for local networks.'
                                }
                            </em>
                            <p>
                                <b>{'Tech Stack: '}</b>
                                {'MERN (MongoDB, Express.js, React, Node.js)'}
                            </p>
                            <p>
                                <b>{'Other Tech: '}</b>
                                {'Web Audio API'}
                            </p>
                            <p>
                                <b>{'Use case: '}</b>
                                {
                                    'Some people (including me) like to maintain their collection of favourite music on their devices, offline. Over time, these collections grow big and it becomes infeasible to store and sync the copies on each device (PC/phone/etc). This was my urge to make this application. User can simply put the collection onto one machine in their home (generally any old pc lying in the garage would work), and setup this player. Then, all the tracks can be easily streamed over the local network through an eye-candy UI, all the basic features someone might need in a music player - albums, playlists, fading/crossfading, gapless playback, equaliser (coming in future version) and obviously, great control over the looks and feel of the player.'
                                }
                            </p>
                        </>
                    }
                />

                <ProjectCard
                    name={'DOMParser'}
                    links={[
                        {
                            link: 'https://github.com/Mynk-9/DOM-Parser',
                            text: 'Github',
                        },
                    ]}
                    desc={
                        <>
                            <em>
                                {
                                    'A small DOM(Document Object Model) Parsing library. Works on C++14 and above.'
                                }
                            </em>
                            <div className={Styles.p}>
                                <b>{'Current capabilities:'}</b>
                                <ol>
                                    <li>{'Scan markup code.'}</li>
                                    <li>
                                        {
                                            'Store the markup data in a tree structure.'
                                        }
                                    </li>
                                    <li>
                                        {
                                            'Storage of the data is optimized in terms of space and time complexity for fast manipulations like moving whole subtrees and multiple deletions-additions of nodes/attributes.'
                                        }
                                    </li>
                                    <li>
                                        {
                                            'Provide minfied or pretty-printed output.'
                                        }
                                    </li>
                                </ol>
                            </div>
                            <div className={Styles.p}>
                                <b>{'How it works:'}</b>
                                <ol>
                                    <li>
                                        {
                                            'Input file is feeded to lexer which reads ahead of parser and creates and stores tokens in a buffer.'
                                        }
                                    </li>
                                    <li>
                                        {
                                            'Parser successively takes tokens from lexer and parses it.'
                                        }
                                    </li>
                                    <li>
                                        {
                                            'The parsed data is saved to a tree data structure.'
                                        }
                                    </li>
                                    <li>
                                        {
                                            'After whatever changes necessary being done to the DOM, user can have two options of printing out the document:'
                                        }
                                        <ol type={'i'}>
                                            <li>{'Minified'}</li>
                                            <li>{'Pretty-printed'}</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </>
                    }
                />

                <ProjectCard
                    name={'wallpepper'}
                    links={[
                        {
                            link: 'https://github.com/Mynk-9/wallpepper',
                            text: 'Github',
                        },
                    ]}
                    desc={
                        <>
                            <em>
                                {
                                    'A UWP (Universal Windows Platform) tool for picking and applying wallpapers from various internet sources. This tool is built for educational purposes only.'
                                }
                            </em>
                            <p>
                                <b>{'Tech Stack: '}</b>
                                {'C# and .net'}
                            </p>
                            <p>
                                <b>{'Other Tech:'}</b>
                                {'Web Audio API'}
                            </p>
                        </>
                    }
                />

                <ProjectCard
                    name={'cowin-poller'}
                    links={[
                        {
                            link: 'https://github.com/Mynk-9/wallpepper',
                            text: 'Github',
                        },
                    ]}
                    desc={
                        <>
                            <em>
                                {
                                    'Poll cowin APIs to check if vaccines are now available.'
                                }
                            </em>
                            <p>
                                <b>{'Tech Stack: '}</b>
                                {'Node.js'}
                            </p>
                            <p>
                                <b>{'Use case: '}</b>
                                {
                                    'During the covid-19 second wave it was getting very difficult to find vaccine slots. This script is designed to be easy to use and configurable according to user\'s city/pincode and set a certain "polling" interval. It polls the government\'s cowin servers for availability of vaccine in the provided area. As soon as it finds a slot(vacancy) it produces a sound(alarm) to remind the user of new vacancies.'
                                }
                            </p>
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default Projects;
