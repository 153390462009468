import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Styles from './Topbar.module.scss';

const TopBar = () => {
    const [colorMode, setColorMode] = useState(
        parseInt(localStorage.getItem('color-mode')) || 0,
    );
    const modeList = ['dark', 'light'];
    const modeText = {
        light: 'light mode',
        dark: 'dark mode',
    };

    // eslint-disable-next-line
    useEffect(() => document.body.classList.add(modeList[colorMode]), []);

    const toggleColorMode = () => {
        setColorMode(_colorMode => {
            document.body.classList.remove(modeList[_colorMode]);
            _colorMode += 1;
            if (_colorMode >= modeList.length) _colorMode = 0;
            document.body.classList.add(modeList[_colorMode]);
            localStorage.setItem('color-mode', _colorMode);
            return _colorMode;
        });
    };

    return (
        <nav className={Styles.nav}>
            <div className={Styles.left}>
                <Link to={'/'}>Mynk-9</Link>
            </div>
            <div className={Styles.right}>
                <Link to={'/blogs'}>Blog</Link>
                <Link to={'/links'}>Links</Link>
                {/* <span>Other Stuff</span> */}
                <span onClick={toggleColorMode}>
                    {modeText[modeList[colorMode]]}
                </span>
            </div>
        </nav>
    );
};

export default TopBar;
