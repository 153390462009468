import React from 'react';
import Styles from './BlogListPage.module.scss';

const BlogListPage = () => {
    return (
        <div className={Styles.page}>
            <div className={`${Styles.header} ${Styles.minor}`}>
                <h1>Blogs</h1>
            </div>
            <div className={Styles.body}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(val => {
                    return (
                        <div className={Styles.details} key={val}>
                            <div className={Styles.line1}>
                                <div className={Styles.title}>
                                    Blog Title - {val}
                                </div>
                                <div className={Styles.actions}>
                                    <a href="/">Read</a>
                                </div>
                            </div>
                            <div className={Styles.line2}>
                                <div className={Styles.date}>
                                    {`${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}`}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BlogListPage;
