import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Typing.module.scss';

const Typing = ({ text }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let clear = false;
        async function animate() {
            for (let i = 0; i < String(text).length; ++i) {
                if (clear) break;
                setIndex(_i => _i + 1);
                await new Promise(r => setTimeout(r, 550));
            }
        }
        animate();

        return () => (clear = true);
    }, [text]);

    return (
        <span className={Styles.typing}>
            {String(text).substring(0, index)}
        </span>
    );
};

Typing.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Typing;
