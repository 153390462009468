import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './App.css';

import MainPageAnimation from './components/mainpageanimation/MainPageAnimation';
import TopBar from './components/topbar/TopBar';

import BlogListPage from './pages/bloglistpage/BlogListPage';
import MainPage from './pages/mainpage/MainPage';
import ProjectsPage from './pages/projects/Projects';
import Links from './pages/links/Links';

const routes = [
    { path: '/', name: 'Home', Component: MainPage },
    { path: '/blogs', name: 'Blog', Component: BlogListPage },
    { path: '/projects', name: 'Projects', Component: ProjectsPage },
    { path: '/links', name: 'Links', Component: Links },
];

const TransitionWrapper = ({ match, Component }) => {
    const nodeRef = useRef(null);

    return (
        <CSSTransition
            in={match != null}
            nodeRef={nodeRef}
            timeout={300}
            classNames={'router-transition'}
            unmountOnExit
        >
            <div style={{ position: 'absolute', width: '100%' }} ref={nodeRef}>
                <Component />
            </div>
        </CSSTransition>
    );
};
TransitionWrapper.propTypes = {
    match: PropTypes.object,
    Component: PropTypes.func,
};

const App = () => {
    return (
        <BrowserRouter>
            <TopBar />
            {window.innerWidth >= 550 ? <MainPageAnimation /> : <></>}

            <div style={{ position: 'relative' }}>
                {routes.map(({ path, name, Component }) => (
                    <Route key={name} exact path={path}>
                        {({ match }) => (
                            <TransitionWrapper
                                match={match}
                                Component={Component}
                            />
                        )}
                    </Route>
                ))}
            </div>
        </BrowserRouter>
    );
};

export default App;
